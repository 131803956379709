import React from 'react';
import Swal from 'sweetalert';
import saveAs from 'file-saver';
// import Select from 'react-select';
import { map, filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import { DataModal } from '../components/Modal';
import { getProductsById } from '../../services';
import SimpleReactValidator from 'simple-react-validator';
import { trackPageView, trackEvent } from '../../helpers/analytics';

const xl = require('excel4node');
const XLSX = require('xlsx');
const cartHelper = require('../../helpers/appHelper');

// const options = [
//   { value: 'Delivery', label: 'Delivery' },
//   { value: 'Pick-Up', label: 'Pick-Up' },
// ];

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      products: [],
      loading: false,
      requestType: '',
      productDetail: {},
      selectedOption: [],
      openDiscountModal: false,
      requestClass: 'is-invalid',
    };
  }

  componentDidMount() {
    this.getRequestType();
    trackPageView();
  }

  getProductsList = async (productId) => {
    const { distributor, dispatch, history } = this.props;
    this.setState({ loading: true });
    const response = await getProductsById(productId);
    if (response) {
      if (response && response.status === 200) {
        const productList = [];
        map(response.data, (product) => {
          map(Object.keys(distributor.business_unit), (item) => {
            let discountedPrice = 0;
            if (item === product.business_unit) {
              const discountPercent = distributor.business_unit[product.business_unit];
              if (discountPercent > 0) {
                const discountprice = product.price * discountPercent;
                discountedPrice = product.price - discountprice;
                product.discounted_price = discountedPrice;
              }
              product.discounted_price = discountedPrice;
              product.asked_price = 0;
              product.approved_price = 0;
              product.asked_discountpercent = 0;
              product.asked_discountprice = 0;
              product.reason = '';
              productList.push(product);
            }
          });
        });
        this.setState({ products: productList || [], loading: false });
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
          await dispatch({ type: 'USER', payload: {} });
          await localStorage.removeItem('token');
          history.push('/');
        }
        this.setState({ loading: false });
        if (response.response.status >= 500) {
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    }
    trackPageView();
  };

  getRequestType = () => {
    const request = localStorage.getItem('request');
    if (request) {
      this.setState(
        {
          requestClass: 'is-valid',
          requestType: request,
        },
        () => {
          this.convertingToOption(request);
        }
      );
    }
  };

  convertingToOption(request) {
    const option = [];
    option.push({ value: request, label: request });
    this.setState({ selectedOption: option });
  }

  updateQuantity(product, quantity) {
    const { cart, user, dispatch } = this.props;
    const { id } = user;
    const updatedCart = cartHelper.addToCart(product, quantity, cart, id);
    dispatch({ type: 'CART', payload: updatedCart });
    trackEvent('Cart', 'Update Quantity');
  }

  removeProduct = (product) => {
    const { cart, user, dispatch } = this.props;
    const { id } = user;
    const updatedCart = cartHelper.removeFromCart(product, cart, id);
    dispatch({ type: 'CART', payload: updatedCart });
    trackEvent('Cart', 'Remove from Cart', `${product.name} (${product.id})`);
  };

  clearCart = (e) => {
    e.preventDefault();
    const { cart, user, dispatch } = this.props;
    const { id } = user;
    const updatedCart = cart.filter((item) => item.userId !== id);
    Swal({
      title: 'Clear Cart',
      text: `Are you sure you want to remove all the products from the cart?`,
      dangerMode: true,
      icon: 'warning',
      buttons: true,
      modal: false,
    }).then(async (status) => {
      if (status) {
        dispatch({ type: 'CART', payload: updatedCart });
        localStorage.removeItem('request');
      }
    });
    trackEvent('Cart', 'Clear Cart');
  };

  downloadTemplate = async () => {
    let wb = new xl.Workbook();
    let ws = wb.addWorksheet('template');
    ws.column(1).setWidth(20);
    ws.column(2).setWidth(20);
    ws.cell(1, 1).string('Layout Number');
    ws.cell(1, 2).string('Quantity');
    wb.writeToBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Template.xlsx');
    });
    trackEvent('Cart', 'Download Template');
  };

  import = async (e, name) => {
    e.preventDefault();
    const { user, dispatch } = this.props;
    const { id } = user;
    const files = e.target.files;
    const file = files[0];
    let reader = new FileReader();
    if (FileReader.prototype.readAsBinaryString === undefined) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
        let binary = '';
        let pt = this;
        let reader = new FileReader();
        reader.onload = function (e) {
          let bytes = new Uint8Array(reader.result);
          let length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          pt.content = binary;
          pt.onload();
        };
        reader.readAsArrayBuffer(fileData);
      };
    }
    reader.onload = async (e) => {
      const target = !e ? reader.content : e.target.result;
      let template = XLSX.read(target, { type: 'binary' });
      const sheet = template.Sheets[template.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let cart = [];
      if (data[0][0] === 'Layout Number' && data[0][1] === 'Quantity') {
        const uploadedProducts = [];
        for (let i = 1; i < data.length; i++) {
          const row = data[i];
          if (row.length === 2) {
            const productId = row[0].toString();
            const quantity = parseInt(row[1]);
            await this.getProductsList(productId);
            uploadedProducts.push(productId);
            const product = filter(this.state.products, (product) => product.id === productId);
            if (product.length > 0 && quantity > 0) {
              if (product[0].portfolio === "red") {
                product[0].discounted_price = 0
              }
              cart = cartHelper.addToCart(product[0], quantity, cart, id);
            } else {
              if (product.length === 0) toast.info(`Unable to find product with layout no. ${productId}`);
              if (quantity === 0) toast.info(`Unable to add product with quantity 0 for layout no. ${productId}.`);
            }
          }
        }
        dispatch({ type: 'CART', payload: cart });
      } else {
        toast.error('Oops! Could not import the file as it is not in a valid format. Please click on Download Template to review the file format');
      }
    };
    reader.readAsBinaryString(file);
    trackEvent('Cart', 'Import Template');
  };
  // downloadQuote = async (e) => {
  //   const { requestClass, requestType } = this.state;
  //   const { user } = this.props;
  //   let cart = this.props.cart || [];
  //   cart = filter(cart, (item) => item.userId === user.id);
  //   if (requestClass === 'is-valid') {
  //     await cartHelper.AdminDownloadQuote(cart, user, '', {}, 0, '', requestType, '');
  //   } else {
  //     toast.error('Please Select type of request');
  //     e.preventDefault();
  //     this.validator.showMessages();
  //     this.forceUpdate();
  //   }
  //   trackEvent('Cart', 'Download Quote');
  // };

  downloadQuote = async (e) => {
    const { user } = this.props;
    let cart = this.props.cart || [];
    cart = filter(cart, (item) => item.userId === user.id);
    await cartHelper.AdminDownloadQuote(cart, user, '', {}, 0, '', '', '');
    trackEvent('Cart', 'Download Quote');
  };

  // submitQuote = async (e) => {
  //   const { history } = this.props;
  //   const { requestClass, requestType } = this.state;
  //   if (requestClass === 'is-valid') {
  //     history.push(`/address/${requestType}`);
  //   } else {
  //     toast.error('Please Select type of request');
  //     e.preventDefault();
  //     this.validator.showMessages();
  //     this.forceUpdate();
  //   }
  //   trackEvent('Cart', 'Submit Quote');
  // };

  submitQuote = async (e) => {
    const { history } = this.props;
    history.push(`/address`);
    trackEvent('Cart', 'Submit Quote');
  };

  onRequestChange = async (selectedOption) => {
    this.setState({ selectedOption }, () => {
      this.setState({
        requestClass: 'is-valid',
        requestType: selectedOption.value,
      });
    });
    await localStorage.setItem('request', selectedOption.value);
    await this.getRequestType();
  };

  handleModel = (productDetail) => {
    this.setState({ productDetail });
  };

  render() {
    const { user } = this.props;
    const { productDetail, loading } = this.state;
    let cart = this.props.cart || [];
    cart = filter(cart, (item) => item.userId === user.id);
    const subTotal = cart.reduce((subTotal, product) => subTotal + product.sub_total, 0);
    // const freight = requestType === 'Delivery' ? (0.5 / 100) * subTotal : requestType === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
    // const totalForTax = freight + subTotal;
    const totalForTax = 0 + subTotal;
    const tax = (12 / 100) * totalForTax;
    // const total = subTotal + freight + tax;
    const total = subTotal + 0 + tax;
    return (
      <>
        <Spinner display={loading}>
          <div className="container-fluid mt-3">
            <div className="mt-5 text-center">
              <button className="btn btn-light mb-2" type="button" onClick={() => this.downloadTemplate()}>
                <i className="fe fe-download"></i> <span className="d-none d-md-none d-lg-none d-xl-inline">Download Template</span>
              </button>
              <div className="custom-file-upload">
                <input
                  type="file"
                  value=""
                  accept=".xlsx"
                  onChange={(e) => {
                    this.import(e, 'uploadFile');
                  }}
                  style={{ overflow: 'hidden' }}
                />
                <button
                  onClick={(e) => {
                    this.import(e, 'uploadFile');
                  }}
                  className="btn btn-light ml-2 mb-2"
                >
                  <i className="fe fe-upload"></i> <span className="d-none d-md-none d-lg-none d-xl-inline">Bulk Import</span>
                </button>
              </div>

              {cart && cart.length > 0 && (
                <>
                  <button className="btn btn-light ml-2 mb-2" type="button" onClick={this.clearCart}>
                    <i className="fe fe-trash" onClick={this.clearCart}></i> <span className="d-none d-md-none d-lg-none d-xl-inline"> Clear Cart</span>
                  </button>
                  <button className="btn btn-light ml-2 mb-2" type="button" onClick={this.downloadQuote}>
                    <i className="fe fe-download" /> <span className="d-none d-md-none d-lg-none d-xl-inline">Download Quote Request</span>
                  </button>
                  <button className="btn btn-success ml-2 mb-2" type="button" onClick={this.submitQuote}>
                    Continue
                  </button>
                </>
              )}
            </div>
            {cart.length > 0 ? (
              <div className="row mt-3">
                <div className="col-md-9 col-xl-10">
                  <div className="card" data-toggle="lists">
                    <div className="table-responsive">
                      <table className="table table-sm table-nowrap card-table">
                        <thead>
                          <tr>
                            <th className="text-center">Sr No</th>
                            <th>Product</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-right">List Price</th>
                            <th className="text-right">Discounted price</th>
                            <th className="text-right">Asked Price</th>
                            <th className="text-right">Sub Total</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {cart &&
                            cart.map((product, index) => (
                              <tr key={product?.id}>
                                <td className="text-center">{index + 1}</td>
                                <td style={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-toggle="tooltip" title={product.name}>
                                  {product.name}
                                  {product?.customer_note && product?.distributor_note && <img src="/img/icons/alert.png" alt="..." className="img-fluid ml-1" style={{ height: '1rem' }} data-toggle="modal" data-target="#productInformationModal" onClick={() => this.handleModel(product)} />}
                                  <p className="small text-muted">{product.id}</p>
                                </td>
                                <td className="text-center">
                                  <button className="btn btn-sm btn-rounded-circle btn-light" onClick={() => this.updateQuantity(product, -1)}>
                                    -
                                  </button>
                                  <button className="btn btn-sm btn-light mx-2">{product?.quantity}</button>
                                  <button className="btn btn-sm btn-rounded-circle btn-light" onClick={() => this.updateQuantity(product, 1)}>
                                    +
                                  </button>
                                </td>
                                <td className="text-right">
                                  <Amount value={product?.price} />
                                </td>
                                <td className="text-center">{product.discounted_price > 0 ? <Amount value={product?.discounted_price} /> : '-'}</td>
                                <td className="text-center" style={{ maxWidth: '10px' }}>
                                  {product?.asked_price > 0 ? <Amount value={`${product?.asked_price}`} /> : '-'}
                                  <br />
                                  {product.reason && (
                                    <div className="btn-group droptop" role="group">
                                      <button id="btnGroupDrop" type="button" className="badge badge-sm badge-info" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Reason
                                      </button>
                                      <div className="dropdown-menu" aria-labelledby="btnGroupDrop" style={{ minWidth: '0px', maxHeight: '40px' }}>
                                        <span className="small text-muted">{product?.reason}</span>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className="text-center">
                                  <Amount value={product?.sub_total} />
                                </td>

                                <td className="text-center" data-toggle="tooltip" title={'Request for discount'}>
                                  <Link to={`/cart/${product.id}/edit`}>
                                    <img src="/img/discount.png" alt="discount" className="d-none d-sm-block" />
                                  </Link>
                                </td>
                                <td className="text-center">
                                  <button className="btn btn-sm btn-rounded-circle btn-light ml-2" onClick={() => this.removeProduct(product)}>
                                    <i className="fe fe-trash" />
                                  </button>
                                </td>
                                <div className="modal fade" id="productInformationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                  <DataModal product={productDetail} />
                                </div>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xl-2">
                  <div className="card">
                    {/* <div className="list-group-item">
                      <div className="text-muted">
                        Type of Request <span className="text-danger">*</span>{' '}
                      </div>
                      <Select value={selectedOption || []} onChange={this.onRequestChange} options={options} required />
                      <span className="text-danger">{this.validator.message('', selectedOption, 'required')}</span>
                    </div> */}
                    <div className="card-body">
                      <div className="list-group list-group-flush my-n3 text-right">
                        <div className="list-group-item">
                          <div className="text-muted">Sub Total</div>
                          <Amount value={subTotal} />
                        </div>
                        {/* <div className="list-group-item">
                          <span className="d-flex justify-content-end">
                            <div className="text-muted">Freight</div>
                          </span>
                          <Amount value={freight} />
                        </div> */}
                        <div className="list-group-item">
                          <div className="text-muted">Tax</div>
                          <Amount value={tax} />
                        </div>
                        <div className="list-group-item">
                          <div className="text-muted">Total</div>
                          <Amount value={total} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="m-5 text-center">
                <div className="row my-5">
                  <div className="col-lg-4 offset-lg-4 text-center my-5">
                    <img src="/img/empty_cart.png" className="img-fluid" alt="no-data-found" />
                    <p className="mt-3">
                      Looks like you have an empty cart! Add <Link to="/products">products</Link> to place your order
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Spinner>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
    distributor: state.distributor,
  };
};

export default connect(mapStateToProps)(Cart);
